jQuery(function ($) {
    var yourNavigation = $('html');
    stickyDiv = "fill-menu";

    $(window).scroll(function () {
        if ($(this).scrollTop() > 75) {
            yourNavigation.addClass(stickyDiv);
        } else {
            yourNavigation.removeClass(stickyDiv);
        }
    });
});
