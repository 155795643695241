jQuery(document).ready(function($) {

    $('.content-carousel').slick({
        dots: false,
        arrows: true,
        appendArrows: '.content-carousel-buttons',
        nextArrow: '<button type="button" class="slick-next"><svg width="14px" height="11px" viewBox="0 0 14 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Elements" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Elements-/-Button-round-arrow" transform="translate(-23.000000, -24.000000)" fill="#ED8F17" fill-rule="nonzero"><path d="M36.6796875,29.7929688 C36.6796875,29.839844 36.6621096,29.8808592 36.6269531,29.9160156 C36.5917967,29.9511721 36.5566408,29.9863279 36.5214844,30.0214844 L32.0214844,34.5214844 C31.9511715,34.603516 31.8662114,34.6621092 31.7666016,34.6972656 C31.6669917,34.7324221 31.5761723,34.75 31.4941406,34.75 C31.4238278,34.75 31.3388677,34.7324221 31.2392578,34.6972656 C31.1396479,34.6621092 31.0488285,34.603516 30.9667969,34.5214844 C30.8261712,34.3691399 30.7558594,34.1933604 30.7558594,33.9941406 C30.7558594,33.7949209 30.8261712,33.6191414 30.9667969,33.4667969 L34.2011719,30.25 L24.0058594,30.25 C23.7714832,30.25 23.5869147,30.1826179 23.4521484,30.0478516 C23.3173821,29.9130853 23.25,29.7285168 23.25,29.4941406 C23.25,29.2714833 23.3173821,29.0927741 23.4521484,28.9580078 C23.5869147,28.8232415 23.7714832,28.7558594 24.0058594,28.7558594 L34.2011719,28.7558594 L30.9667969,25.5214844 C30.8261712,25.3691399 30.7558594,25.1933604 30.7558594,24.9941406 C30.7558594,24.7949209 30.8261712,24.6191414 30.9667969,24.4667969 C31.1191414,24.3261712 31.2949209,24.2558594 31.4941406,24.2558594 C31.6933604,24.2558594 31.8691399,24.3261712 32.0214844,24.4667969 L36.5214844,28.9667969 C36.5566408,29.0136721 36.5830077,29.0546873 36.6005859,29.0898438 C36.6181642,29.1250002 36.6445311,29.1601561 36.6796875,29.1953125 C36.7148439,29.3125006 36.7324219,29.4238276 36.7324219,29.5292969 C36.7324219,29.6347662 36.7148439,29.7226559 36.6796875,29.7929688 Z" id=""></path></g></g></svg></button>',
        prevArrow: '<button type="button" class="slick-prev"><svg width="14px" height="11px" viewBox="0 0 14 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Elements" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Elements-/-Button-round-arrow" transform="translate(-23.000000, -24.000000)" fill="#ED8F17" fill-rule="nonzero"><path d="M36.6796875,29.7929688 C36.6796875,29.839844 36.6621096,29.8808592 36.6269531,29.9160156 C36.5917967,29.9511721 36.5566408,29.9863279 36.5214844,30.0214844 L32.0214844,34.5214844 C31.9511715,34.603516 31.8662114,34.6621092 31.7666016,34.6972656 C31.6669917,34.7324221 31.5761723,34.75 31.4941406,34.75 C31.4238278,34.75 31.3388677,34.7324221 31.2392578,34.6972656 C31.1396479,34.6621092 31.0488285,34.603516 30.9667969,34.5214844 C30.8261712,34.3691399 30.7558594,34.1933604 30.7558594,33.9941406 C30.7558594,33.7949209 30.8261712,33.6191414 30.9667969,33.4667969 L34.2011719,30.25 L24.0058594,30.25 C23.7714832,30.25 23.5869147,30.1826179 23.4521484,30.0478516 C23.3173821,29.9130853 23.25,29.7285168 23.25,29.4941406 C23.25,29.2714833 23.3173821,29.0927741 23.4521484,28.9580078 C23.5869147,28.8232415 23.7714832,28.7558594 24.0058594,28.7558594 L34.2011719,28.7558594 L30.9667969,25.5214844 C30.8261712,25.3691399 30.7558594,25.1933604 30.7558594,24.9941406 C30.7558594,24.7949209 30.8261712,24.6191414 30.9667969,24.4667969 C31.1191414,24.3261712 31.2949209,24.2558594 31.4941406,24.2558594 C31.6933604,24.2558594 31.8691399,24.3261712 32.0214844,24.4667969 L36.5214844,28.9667969 C36.5566408,29.0136721 36.5830077,29.0546873 36.6005859,29.0898438 C36.6181642,29.1250002 36.6445311,29.1601561 36.6796875,29.1953125 C36.7148439,29.3125006 36.7324219,29.4238276 36.7324219,29.5292969 C36.7324219,29.6347662 36.7148439,29.7226559 36.6796875,29.7929688 Z" id=""></path></g></g></svg></button>',
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,

    });

});
