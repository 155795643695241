/* jshint ignore:start */
jQuery(document).ready(function ($) {

    function scrollVisuals50percent() {
        var percent50 = $('.js-visuals')[0].scrollWidth,
            containerwidth = $('.fullwidth').width(),
            scrollpos = (percent50 - containerwidth) / 2;

        $('.js-visuals').scrollLeft(scrollpos);
    }

    function loadItems(tid) {
        var active_category = jQuery('.js-filters').find('.__active').data('category');

        jQuery.ajax({
            url: "/wp-admin/admin-ajax.php?action=get_producten",
            data: {
                term_id: tid
            },
            type: 'POST',
            beforeSend:
                function () {
                  $('.js-producten').addClass('loading');
                },
            success:
                function (result) {
                    var data = JSON.parse(result),
                        container = $('.js-producten');

                    container.removeClass('loading');
                    container.empty();
                    container.append(data);
                }
        })
    }

    if ($('.js-visuals').length) {
        scrollVisuals50percent();
    }

    $('.js-select').on('change', function (e) {
        var _ = $(this),
            term_id = _.children("option:selected").val();

        loadItems(term_id);
    });


    $('.js-pill').on('click', function (e) {
        var _ = $(this),
            container = _.closest('.js-container'),
            tid = _.data('tid');

        container.find('.js-pill').removeClass('__active');
        _.addClass('__active');
        loadItems(tid);
    });

    $('.hamburger').on('click', function (e) {
        var _ = $(this);
        _.toggleClass('is-active');
        $('#mobile-menu').toggleClass('is-active');
        $('html').toggleClass('menu-is-open');
    });

    $('.js-scrollto-handle').on('click', function (e) {
        var _ = $(this),
            container = _.closest('.js-scrollto-container'),
            topposition = container.offset().top - 120 - 96;
        //120 = de negatieve margin van het element 96 is de hoogte van het menu


        console.log(topposition);

        $('html, body').animate({scrollTop: topposition}, 600);
        return false;
    });

    if($('.fwHeader').length){
        $('body').addClass('yesheader');
    }else{
        $('body').addClass('noheader');
    }

    $('.js-playvideo').magnificPopup({
        type: 'iframe',
        iframe: {
            patterns: {
                youtube: {
                    index: 'youtube.com/',
                    id: function (url) {
                        var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
                        if (!m || !m[1]) return null;
                        return m[1];
                    },
                    src: '//www.youtube.com/embed/%id%?autoplay=1'
                },
                vimeo: {
                    index: 'vimeo.com/',
                    id: function (url) {
                        var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
                        if (!m || !m[5]) return null;
                        return m[5];
                    },
                    src: '//player.vimeo.com/video/%id%?autoplay=1'
                }
            }
        }
    });
});